.App {
  margin-top: 0px;
  text-align: center;
  min-height: 100vh;
  background-image: url('https://media-public.canva.com/z0C2E/MAE1_jz0C2E/1/s2.jpg');
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.App h1,h2{
  padding-top: 25px;
  color:#D1C76D;
}
.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content:left;
  color:#D1C76D;
  font-size: calc(10px + 2vmin);
  border: 2px solid #D1C76D;
  border-spacing: 15px 50px;
}
.submit {
  margin-top: 15px;
  width: 100%;
  background-color: #D1C76D;
  border-radius: 12px;
  font-size: calc(10px + 2vmin);
  margin-bottom: 50px;
  
}
.inputs {
 margin-top:50px;
}
.labels{
  margin-left: 15px;
  margin-right: 15px;
}
.attendees {
  flex: 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}
.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  background-color: red;
}
.totalrsvp {
  font-size: 1px;
}

